import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import LevelledRenderer from '../components/renderers/levelledRenderer'
import TextRenderer from '../components/renderers/textRenderer'
import '../css/main.min.css'

class View extends React.Component {
  render() {
    const { pageData, genericData, allMarkdownRemark } = this.props.data
    const { frontmatter: pageFrontmatter, html } = pageData
    const { frontmatter: genericFrontmatter } = genericData

    return (
      <Layout
        data={{
          allMarkdownRemark: allMarkdownRemark,
        }}
      >
        {pageFrontmatter.yaml === true ? (
          <div>
            <LevelledRenderer
              pageData={pageFrontmatter}
              genericData={genericFrontmatter}
              html={html}
            />
          </div>
        ) : (
          <TextRenderer data={pageFrontmatter} html={html} />
        )}
      </Layout>
    )
  }
}

export default function FrameworkView({ data }) {
  return <View data={data} />
}

export const pageQuery = graphql`
  query($path: String!, $isYaml: Boolean!) {
    allMarkdownRemark: allMarkdownRemark {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            path
            sidebarTitle
            sidebarGroup
          }
        }
      }
    }
    pageData: markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        sidebarTitle
        sidebarGroup
        yaml
        levels
        homepage
        topics @include(if: $isYaml) {
          name
          title
          content {
            level
            criteria {
              description
              skill
              examples
            }
          }
        }
        skills @include(if: $isYaml) {
          name
          description
          category
        }
      }
    }
    genericData: markdownRemark(frontmatter: { path: { eq: "/generic" } }) {
      html
      frontmatter {
        path
        title
        sidebarTitle
        sidebarGroup
        yaml
        levels
        homepage
        topics @include(if: $isYaml) {
          name
          title
          content {
            level
            criteria {
              description
              skill
            }
          }
        }
        skills @include(if: $isYaml) {
          name
          description
        }
      }
    }
  }
`
