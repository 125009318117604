export const match = (array1, array2) =>
  array1.length === array2.length &&
  array1.sort().every(function(value, index) {
    return value === array2.sort()[index]
  })

export const contains = (arrayToQuestion, arrayOfElements) =>
  arrayOfElements.filter(animal => arrayToQuestion.includes(animal)).length !==
  0

export const groupBy = (arrayToGroup, propertyToGroupOn) => {
  // Must be an array and must be defined and have a value
  if (arrayToGroup && Array.isArray(arrayToGroup)) {
    var groups = {}

    arrayToGroup.forEach(function (item) {
      var groupName = item[propertyToGroupOn];

      // Check if group exists, and if not, let's create it
      if (!(groupName in groups)) {
        var group = {}

        // Keep the original property name on the group
        // And let's use the current item's value as the group name
        group[propertyToGroupOn] = groupName
        group.children = []

        groups[groupName] = group;
      }

      // Add to existing group
      groups[groupName].children.push(item)
    })

    return groups
  }

  // Base case if data is incorrect
  return null
}
