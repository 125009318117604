import * as React from 'react'
import * as R from 'ramda'
import Masonry from 'react-masonry-css'
import { contains, match, groupBy } from '../../utils/arrayUtils'
import Header from '../header'
import {
  Card,
  CardContentList,
  CardTitle,
  CardTitleGroup,
  CenteredElement,
  FrameworkCard,
  PrimaryView,
  Subtitle,
  ExamplesText,
  BREAKPOINT_TABLET,
  BREAKPOINT_DESKTOP,
  MarkdownContent,
} from '../styles'

const masonryBreakpoints = {
  default: 2,
  [BREAKPOINT_DESKTOP]: 2,
  [BREAKPOINT_TABLET]: 1,
}

class ExampleCriteriaComponent extends React.Component {
  state = { isHidden: true }

  toggleView = (event) => {
    event != null ? event.preventDefault() : null
    this.setState({
      isHidden: !this.state.isHidden,
    })
  }

  generateExamples = (content) => {
    if (content.examples != null && content.examples.length > 0) {
      let criteria = content.examples.map((val, i) => (
        <li key={i + '-' + Math.random()}>{val}</li>
      ))

      return <ul>{criteria}</ul>
    }
    
    return null
  }

  render() {
    const { content } = this.props
    const { isHidden } = this.state

    return (
      <li style={{"list-style-type": (content.examples && content.examples.length > 0 ? (isHidden ? "\"+ \"" : "\"- \"") : "disk")}}>
        <ExamplesText hasExamples={content.examples && content.examples.length > 0} onClick={this.toggleView}>
          <b className="skill">{content.skill}</b>: {content.description}
          {(content.examples && content.examples.length > 0 && !isHidden) ? <div><b className="examples">Examples</b></div> : null}
        </ExamplesText>
        {!isHidden ? this.generateExamples(content) : null}
      </li>
    )
  }
}

function Skills (props) {
  return (
    <div>
      <h3>Skills</h3>
      <p>These are the categories and skills we assess every member of the team on. They are used to generate your overall level with respect to this framework.</p>
      {Object.entries(props.data).map(([key, group]) => {
        return (
        <div key={key}>
          <br/>
          <h4 key={key}>{key}</h4>
          <table>
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              {group.children.map(skill => {
                return (
                  <tr key={skill.name}>
                    <td>{skill.name}</td>
                    <td>{skill.description}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )
      })}
    </div>
  )
}

export default class LevelledRenderer extends React.Component {
  constructor(props) {
    super(props)
    const genericDataTitles = props.genericData.topics.map(obj => obj.title)
    const pageDataTitles = props.pageData.topics.map(obj => obj.title)
    const genericDataNames = props.genericData.topics.map(obj => obj.name)
    const pageDataNames = props.pageData.topics.map(obj => obj.name)
    const pageDataSkills = props.pageData.skills
    const pageDataSkillsGrouped = groupBy(props.pageData.skills, "category")

    // isGeneric checks to see if the variable names and the "Title" is present on both the generic
    // and page date we receive. This should only flag the generic framework, unless someone redeclares the
    // framework titles and variable names in a new framework (which should not get past review)
    const matchingNames = match(genericDataNames, pageDataNames)
    const matchingTitles = match(genericDataTitles, pageDataTitles)
    const isGeneric = matchingNames && matchingTitles

    // inheritsGeneric checks to see if some of the page data and generic framework variable
    // names are shared which is how our inheritance works), but the titles do not exactly match
    const containingNames = contains(genericDataNames, pageDataNames)
    const inheritsGeneric = containingNames && !matchingTitles

    this.state = {
      level: null,
      isGeneric,
      inheritsGeneric,
      skills: pageDataSkills,
      skillsGrouped: pageDataSkillsGrouped
    }
  }

  handleClick = (val) => (event) => {
    event.preventDefault()
    this.setState({
      level: val,
    })
  }

  renderPageHeader() {
    const { pageData } = this.props
    const { level } = this.state

    return (
      <Header
        onClickHandler={this.handleClick}
        pageData={pageData}
        activeLevel={level}
      />
    )
  }

  renderEmptyState() {
    const { pageData, html } = this.props

    if (pageData.homepage === true && html !== '') {
      return (
        <Card>
          <MarkdownContent dangerouslySetInnerHTML={{ __html: html }} />
          {this.state.skillsGrouped && Object.keys(this.state.skillsGrouped).length && <Skills data={this.state.skillsGrouped} />}
        </Card>
      )
    } else {
      return (
        <CenteredElement>
          <Card>
            <Subtitle>
              To view a framework, please select a level above.
            </Subtitle>
          </Card>
        </CenteredElement>
      )
    }
  }

  renderFramework() {
    const { pageData } = this.props
    let content = pageData.topics.map(topic => this.createTopic(topic))

    return (
      <Masonry
        breakpointCols={masonryBreakpoints}
        columnClassName="framework-columns"
        className="framework-view"
      >
        {content}
      </Masonry>
    )
  }

  createTopic = (topic) => {
    const { genericData } = this.props
    const { level, isGeneric } = this.state
    const genericTopic = genericData.topics.filter(
      obj => obj.name === topic.name,
    )

    const title =
      genericTopic != null && !R.isEmpty(genericTopic)
        ? genericTopic.map(obj => obj.title)[0]
        : topic.title

    const frameworkCriteria = topic.content
      .filter(objContent => objContent.level === level)
      .map(objContent =>
        objContent.criteria != null
          ? objContent.criteria.map((val, i) => {
            return (
              <ExampleCriteriaComponent key={i + '-' + Math.random()} content={val}></ExampleCriteriaComponent>
            )
          })
          : null,
      )
    
    const genericCriteria =
      genericTopic != null && !R.isEmpty(genericTopic)
        ? genericTopic.map(obj =>
            obj.content
              .filter(objContent => objContent.level === level)
              .map(objContent => objContent.criteria != null ?
                objContent.criteria.map((val, i) => (
                <li key={i + '-' + Math.random()}>{val.description}</li>
                )) : null,
              ),
          )
        : null

    if (
      (genericCriteria != null && !R.isEmpty(genericCriteria)) ||
      (frameworkCriteria != null && !R.isEmpty(frameworkCriteria))
    ) {
      return (
        <FrameworkCard key={topic.name}>
          <CardTitleGroup>
            <CardTitle>{title}</CardTitle>
          </CardTitleGroup>
          <CardContentList>
            {frameworkCriteria != null && !R.isEmpty(frameworkCriteria)
              ? frameworkCriteria
              : null}

            {!isGeneric &&
            !R.isEmpty(genericCriteria) &&
            genericCriteria != null
              ? genericCriteria
              : null}
          </CardContentList>
        </FrameworkCard>
      )
    }
  }

  render() {
    const { level } = this.state

    return (
      <PrimaryView>
        {this.renderPageHeader()}
        {level != null ? this.renderFramework() : this.renderEmptyState()}
      </PrimaryView>
    )
  }
}
